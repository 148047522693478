/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        if ($(window).width() < '768') {

          $('a.dropdown-toggle-parent').on("click", function (e) {
            $(this).next('ul').toggle();
          });

          $('a.dropdown-toggle-sub').on("click", function (e) {
            $(this).next('ul').toggle();
          });
        } else {
          $(window).on('click', function (e) {
            $('.dropdown-menu:visible, .dropdown-sub .dropdown-menu:visible').toggle();
          });

          $('a.dropdown-toggle-parent').on("click", function (e) {
            e.stopPropagation();

            if ($(this).next('ul').is(':visible')) {
              $('.dropdown-menu:visible').toggle();
            } else {
              $('.dropdown-menu:visible').toggle();
              $(this).next('ul').toggle();
            }
          });


          $('a.dropdown-toggle-sub').on("click", function (e) {
            e.stopPropagation();

            if ($(this).next('ul').is(':visible')) {
              $('.dropdown-sub .dropdown-menu:visible').toggle();
            } else {
              $('.dropdown-sub .dropdown-menu:visible').toggle();
              $(this).next('ul').toggle();
            }
          });
        }
        $('.fancy-menu').click(function (e) {
          e.preventDefault();
          $(this).toggleClass('active');
        });


        //	Scrolltop Button
        $('.scrolltop').click(function () {
          $('html, body').animate({scrollTop: 0}, 500);
        });

        //	Affix
        var header_height = $('header').height();
        if ($('.top-footer').is(':visible')) {
          var footerTop_height = $('.top-footer').outerHeight();
        } else {
          var footerTop_height = 0;
        }

        // //	ScrollTo
        var jump = function (e) {
          if (e) {
            e.preventDefault();
            var target = $(this).attr("href");
          } else {
            var target = location.hash;
          }

          $('html,body').animate(
            {
              scrollTop: $(target).offset().top - 200
            }, 200, function () {
              location.hash = target;
            });
        };

        // $(document).ready(function() {
        //     $('a[href^="#"]').on("click", jump);
        //     if (location.hash){
        //         setTimeout(function(){
        //             $('html, body').scrollTop(0).show();
        //             jump();
        //         }, 0);
        //     }else{
        //         $('html, body').show();
        //     }
        // });


        //test
        $("[data-toggle]").on("click", function () {
          var target = $(this).data("toggle");
          $('[data-section=' + target + ']').slideToggle('fast');
        });


        $('[data-smart-affix]').scrollToFixed({
          limit: function () {
            return ($('footer').offset().top - footerTop_height) - $(this).outerHeight(true) - 50;
          },
          minWidth: 992,
          marginTop: $('header').outerHeight(true),
          zIndex: 99
        });



        //	Selectpicker
        $('select').selectpicker({
          dropupAuto: false,
        });

        if(is_allowed_cookie('facebook')) {
          $.each($('.liberta_embed'), function (index, value) {
            var elem = $(value);
            var embed = elem.attr('data-embed');

            elem.removeClass('liberta_embed--no-cookie');
            elem.html(embed);
          })
        }
      },
      finalize: function () {

      }
    },

    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page

        $('.homepage-slider').owlCarousel({
          items: 1,
          loop: true,
          autoplay: true,
          animateOut: 'fadeOut',
          animateIn: 'fadeIn'
        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


})(jQuery); // Fully reference jQuery after this point.






